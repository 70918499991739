import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';
/**
 * Initializes the hero image slider
 */
class Carousel {
  static ProductSlider: TinySliderInstance;
  static MobileProductSlider: TinySliderInstance;
  static FramesSlider: TinySliderInstance;
  // static PremiumProductSlider: TinySliderInstance;
  static FeaturedContentSlider: TinySliderInstance;
  static AddonProductsSlider: TinySliderInstance;

  static init(): void {
    Carousel.initProductSlideshow();
    Carousel.initMobileProductSlideshow();
    Carousel.initHeroCarousel();
    Carousel.initProductCardSlider();
    Carousel.initFramesSlideshow();
    Carousel.initFeaturedContentSlideShow();
    Carousel.initAddonSwiper();
  }

  /**
   * Init Hero Carousel
   */
  static initHeroCarousel(): void {
    const containerSelector = '.js-hero-carousel';
    const carouselContainer: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);
    const autoPlay = !!document.querySelector('.js-carousel-autoplay');

    if (carouselContainer.length === 0) {
      return;
    }

    tns({
      container: containerSelector,
      items: 1,
      mode: 'carousel',
      controlsContainer: '.js-hero-controls',
      autoplay: autoPlay,
      autoplayButton: autoPlay ? '.js-carousel-autoplay' : false,
      autoplayHoverPause: autoPlay,
      autoHeight: true,
      rewind: true,
      lazyload: true,
      navContainer: '.js-hero-carousel-indicators',
    });
  }

  /**
   * Init Modal Product Slideshow
   * @param selector - the selector for the current slideshow container
   */
  static initProductSlideshow(selector = '.js-slideshow-content-container-art-prints'): void {
    const containerSelector = `${selector} .js-product-slideshow`;
    const container: HTMLElement = document.querySelector(selector);

    if (container === null) {
      return;
    }

    Carousel.ProductSlider = tns({
      container: containerSelector,
      loop: true,
      navContainer: `${selector} .js-product-slideshow-nav`,
      controlsContainer: `${selector} .js-slideshow-controls`,
      items: 1,
      navAsThumbnails: true,
      mouseDrag: true,
      controls: true,
      startIndex: 0,
      autoHeight: true,
      lazyload: true, // class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified
    });

    // update slider height is needed when using autoHeight and lazyLoad
    const images = container.querySelectorAll('img');
    images.forEach(img => {
      img.addEventListener('load', () => {
        Carousel.ProductSlider.updateSliderHeight();
      })
    });
  }

  /**
   * Init mobile product slideshow
   * @param selector - the wrapping container selector
   */
  static initMobileProductSlideshow(selector = '.js-clean-product-slideshow'): void {
    const containerSelector = `${selector} .js-mobile-product-slideshow`;
    const container: HTMLElement = document.querySelector(containerSelector);

    // if contains tns-slider -> already initialized
    if (container === null || container.classList.contains('tns-slider')) {
      return;
    }

    Carousel.MobileProductSlider = tns({
      container: containerSelector,
      mouseDrag: true,
      center: true,
      items: 1,
      edgePadding: 0,
      gutter: 1,
      preventScrollOnTouch: 'auto', // prevent page scrolling when swipe:ing.
      controls: false,
      nav: true,
      autoHeight: true,
      lazyload: true,
      loop: false,
    });

    // set new height when video is loaded
    const videos = container.querySelectorAll('video');
    videos.forEach(video => {
      video.addEventListener('loadeddata', function () {
        Carousel.MobileProductSlider.updateSliderHeight();
      });
    });

    // setup lightbox modal
    const photoModal: HTMLElement = document.querySelector('.js-mobile-photo-modal');
    const imgElement: HTMLImageElement = photoModal.querySelector('.js-img');
    
    photoModal.addEventListener('show.bs.modal', (event: any) => { // eslint-disable-line
      const trigger: HTMLLinkElement = event.relatedTarget;

      imgElement.setAttribute('src', trigger.dataset.photo);

       setTimeout(function () {
         imgElement.classList.add('show');
       }, 200);
    }, false);
  }

  /**
   * Init product card slider/carousel
   * */
  static initProductCardSlider(): void {
    const containersSelector = '.js-product-card-slider:not(.tns-slider)'; // only select not initiated
    const productCardSliderElements: NodeListOf<HTMLElement> = document.querySelectorAll(containersSelector);

    if (productCardSliderElements === null) {
      return;
    }

    const productCardSliders = [];

    productCardSliderElements.forEach((elm: HTMLElement) => {
      const slider = tns({
        container: elm,
        controls: false,
        mouseDrag: true,
        lazyload: true, // class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified and set data-src
        responsive: {
          900: {
            controls: true,
          }
        }
      });

      productCardSliders.push(slider);
    });
  }

  /**
   * Init Modal Frames Slideshow
   */
  static initFramesSlideshow(): void {
    const containerSelector = '.js-frames-slideshow';
    const container: HTMLElement = document.querySelector(containerSelector);

    if (container === null) {
      return;
    }

    const modal: HTMLElement = document.querySelector('.js-frames-info-modal');
    const controls: HTMLElement = modal.querySelector('.js-slideshow-controls');

    Carousel.FramesSlider = tns({
      container: containerSelector,
      controls: false,
      items: 1,
      mouseDrag: true,
      nav: false,
      lazyload: true, // Class .tns-lazy-img need to be set on every image you want to lazyload if option lazyloadSelector is not specified
    });

    const prev = controls.querySelector('.js-slideshow-controls-prev');
    const next = controls.querySelector('.js-slideshow-controls-next');

    prev.addEventListener('click', () => {
      Carousel.FramesSlider.goTo('prev');
    });

    next.addEventListener('click', () => {
      Carousel.FramesSlider.goTo('next');
    });
  }

  /**
   * Init Featured Content Slideshow
   */
  static initFeaturedContentSlideShow(): void {
    const containerSelector = '.js-featured-slideshow:not(.tns-slider)';
    const containers: NodeListOf<HTMLElement> = document.querySelectorAll(containerSelector);
 
    if (containers === null) {
      return;
    }

    const featuredSliders = [];

    containers.forEach(() => {
      const slider = tns({
        container: containerSelector,
        mouseDrag: true,
        center: false,
        items: 1,
        edgePadding: 50,
        gutter: 18,
        preventScrollOnTouch: 'auto', // prevent page scrolling when swipe:ing.
        controls: false,
        nav: false,
        autoHeight: true,
        autoWidth: true,
        lazyload: true,
        loop: false,
        responsive: {
          992: {
            items: 4,
            controls: true,
            controlsText: ['', ''],
            autoWidth: false,
            arrowKeys: true,
          }
        }
      });

      featuredSliders.push(slider);
    });
  }

  /**
   * Initialize addon carousel/swiper
   * */
  static initAddonSwiper(): void {
    const containerSelector = '.js-addon-swipe-wrapper:not(.tns-slider)';
    const container: HTMLElement = document.querySelector(containerSelector);

    if (container === null) {
      return;
    }

    Carousel.AddonProductsSlider = tns({
      container: containerSelector,
      mouseDrag: true,
      preventScrollOnTouch: 'auto', // prevent page scrolling when swpie:ing.
      controls: false,
      nav: false,
      autoHeight: false,
      center: false,
      lazyload: true,
      items: 5,
      edgePadding: 0,
      loop: false,
    });
  }
}

export default Carousel;