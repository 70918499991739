import { CartItemAdd, CartItemAddonAdd, FacebookEvent } from '../models';
import { Margin } from '../enums';
import CartService from './cart-service';
import Ecommerce from './ecommerce';
import Utilities from './utilities';
import FacebookConversionService from './facebook-conversion-service';
import Carousel from './carousel';

/**
 * Shopping cart
 */
class Cart {
  static MiniCartToggle: HTMLElement = document.querySelector('.js-toggle-minicart');
  static MiniCartContainer: HTMLElement = document.querySelector('.js-minicart');
  static AddToCartForm: HTMLFormElement = document.querySelector('.js-add-to-cart-form');
  static AddToCartFormPremium: HTMLFormElement = document.querySelector('.js-add-to-cart-form-premium');
  static DeleteFromCartButtonSelector = '.js-delete-from-cart';
  static EditCartItemButtonSelector = '.js-edit-cart-item-btn';
  static EditCartItemModalSelector = '#editCartItemModal';
  static CartCountSelector = '.js-cart-count';

  static AddToCartButtonList: NodeListOf<HTMLElement> = document.querySelectorAll('.js-add-to-cart-btn');

  /**
   * Initializes the shopping cart
   */
  static init(): void {
    // setup initial event listener

    Cart.AddToCartButtonList.forEach(btn => {
      btn.addEventListener('click', (e: Event) => {
        e.preventDefault();
        Cart.addToCart(btn as HTMLButtonElement);
      });
    });

    // setup minicart toggle
    Cart.MiniCartToggle.addEventListener('click', (e: Event): void => {
      e.preventDefault();
      this.miniCartToggle();
    });

    // render initial cart content
    Cart.renderCart();

    // set current currency in cached header partial
    const currencyContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-current-currency');

    currencyContainers.forEach(elm => {
      elm.innerText = window.Printler.Currency;
    })
  }

  /**
   * Setup event listeners for add to cart buttons , initiated when addons loaded (product-selector)
   */
  static setupAddToCartButtonsEvent(): void {
    // buttons needs to be fetched in this function since these buttons are added dynamically
    const addAccessoryToCartButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-add-accessory-to-cart-btn');

    addAccessoryToCartButtons.forEach(btn => {
      btn.addEventListener('click', (e: Event) => {
        e.preventDefault();
        Cart.addAccessoryToCart(btn);
      });
    });
  }

  /**
   * Toggle minicart
   */
  static miniCartToggle(): void {
    const isOpenClass = 'is-open';
    const body: HTMLElement = document.querySelector('body');

    if (Cart.MiniCartContainer === null) {
      return;
    }

    if (Cart.MiniCartContainer.classList.contains(isOpenClass)) {
      Cart.MiniCartContainer.classList.remove(isOpenClass);
      Cart.MiniCartToggle.setAttribute('aria-expanded', 'false');
      document.querySelector('.modal-backdrop')?.remove();
      body.classList.remove('cart-is-open');
      document.querySelector('.o-edit-cart-item-modal-backdrop')?.remove();
      document.querySelector('.o-site-nav')?.classList.remove('cart-open');

      setTimeout(() => {
        Cart.MiniCartContainer.style.display = 'none';
      }, 200);
    } else {
      document.querySelector('.o-site-nav')?.classList.add('cart-open');

      // show the backdrop
      const backdrop: HTMLElement = document.createElement('div');
      backdrop.classList.add('o-edit-cart-item-modal-backdrop', 'show', 'fade');
      body.append(backdrop);

      Cart.MiniCartContainer.style.display = 'block';

      setTimeout(() => {
        Cart.MiniCartContainer.classList.add(isOpenClass);
        Cart.MiniCartToggle.setAttribute('aria-expanded', 'true');
        body.classList.add('cart-is-open');
      }, 200);

      // push gtm event
      Ecommerce.pushViewCartEvent();
    }
  }

  /**
   * Setup event listeners for add to cart form
   */
  static setupFormSubmitEvent(): void {
    const updateCartItemForm = document.querySelector('.js-edit-cart-item-form');
    const updateCartSubmitBtn: HTMLButtonElement = updateCartItemForm.querySelector('.js-add-to-cart-btn');
    // updateCartSubmitBtn?.addEventListener('click', this.addToCart);

    updateCartSubmitBtn?.addEventListener('click', (e: Event) => {
      e.preventDefault();
      Cart.addToCart(updateCartSubmitBtn);
    });
  }

  /**
   * Setup click listener - on cart close
   */
  static setupCloseMiniCartEventListener(): void {
    const closeCartButton: HTMLElement = document.querySelector('.js-minicart-close');

    closeCartButton?.addEventListener('click', (e: Event) => {
      e.preventDefault();
      Cart.miniCartToggle();
    });

    document.body.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.key === '27') {
        e.preventDefault();
        if (Cart.MiniCartToggle.getAttribute('aria-expanded') === 'true') {
          Cart.miniCartToggle();
        }
      }
    });
  }

  /**
   * Setup event listener for delete cart items
   */
  static setupDeleteItemEventListener(): void {
    const deleteItemButtons: NodeListOf<HTMLElement> = Cart.MiniCartContainer.querySelectorAll(Cart.DeleteFromCartButtonSelector);

    deleteItemButtons.forEach(button => {
      button.addEventListener('click', Cart.deleteFromCart);
    });
  }

  /**
   * Setup event listener for edit cart items
   */
  static setupEditItemEventListener(): void {
    const editItemButtons: NodeListOf<HTMLElement> = Cart.MiniCartContainer.querySelectorAll(Cart.EditCartItemButtonSelector);

    editItemButtons.forEach(button => {
      button.addEventListener('click', (e: Event) => {
        e.preventDefault();

        Cart.editCartItemClick(button);
      });
    });
  }

  /**
   * Edit cart item click event
   * @param button - button element
   */
  static editCartItemClick(button: HTMLElement): void {
    const editItemModalElement: HTMLElement = document.querySelector(Cart.EditCartItemModalSelector);

    if (editItemModalElement.classList.contains('show')) {
      return;
    }

    // setup modal
    editItemModalElement.classList.add('in');
    editItemModalElement.setAttribute('aria-hidden', 'false');
    editItemModalElement.style.display = 'block';

    // populate content in modal based on clicked button cart item id
    const selectorContainer: HTMLElement = document.querySelector('.js-edit-product-selector-container');
    const spinner = document.createElement('div');
    spinner.classList.add('a-spinner');

    // clear previous content
    selectorContainer.innerHTML = '';
    selectorContainer.appendChild(spinner);

    CartService.getProductSelectorHtml(+button.dataset.item, +button.dataset.addon).then(result => {
      // get selector container and append
      selectorContainer.innerHTML = result;

      // re-init Bootstrap Native - product group buttons init
      window.BSN.initCallback();

      // setup add to cart form event listener
      Cart.setupFormSubmitEvent();

      const thumb: HTMLImageElement = document.querySelector('.js-edit-cart-item-thumb');
      thumb.dataset.src = button.dataset.photo;

      // setup close modal buttons
      const closeButtons = document.querySelectorAll('.js-close-edit-cart-item-modal');
      closeButtons.forEach(closeButton => {
        closeButton.addEventListener('click', (e: Event) => {
          e.preventDefault();

          Cart.closeModal(document.querySelector(Cart.EditCartItemModalSelector));
        });
      });

      // initialize addon swipe
      Carousel.initAddonSwiper();
    });

    // timeout for fade in transition
    setTimeout(() => {
      editItemModalElement.classList.add('show');
    }, 150);
  }

  /**
   * Close modal and remove backdrop
   * @param modal - the modal element to close
   */
  static closeModal(modal: HTMLElement): void {
    const backdrop: HTMLElement = document.querySelector('.modal-backdrop');

    modal.classList.remove('show');
    modal.style.display = 'none';
    modal.setAttribute('aria-hidden', 'true');

    backdrop?.remove();
  }

  /**
   * Add to cart
   * @param e - submit button click event
   */
  static addToCart(btn: HTMLButtonElement): void {
    const submitButton: HTMLButtonElement = btn;
    const addToCartForm: HTMLFormElement = submitButton?.closest('form');

    const formFields: NodeListOf<HTMLElement> = addToCartForm.querySelectorAll('input, select');
    const formFieldsArray = Array.from(formFields).filter(x => x.getAttribute('disabled') === null); // filter disabled fields

    const selectedProductField = formFieldsArray.find(field => field.getAttribute('name') === 'selectedProductId' && field.getAttribute('checked') !== null) as HTMLInputElement;
    const editProductField = formFieldsArray.find(field => field.getAttribute('name') === 'editProductId') as HTMLInputElement;

    const withMarginRadio: HTMLInputElement = formFieldsArray.find(field => field.getAttribute('name') === 'options[Margin]' && field.getAttribute('value') === Margin.WithMargin) as HTMLInputElement;
    const selectedFrameField = formFieldsArray.find(field => field.getAttribute('name') === 'selectedFrameId' && field.getAttribute('checked') !== null) as HTMLInputElement;

    const passepartoutRadio: HTMLInputElement = addToCartForm.querySelector('.js-passepartout-prd');

    let frameAddonValue = +selectedFrameField?.dataset.value;
    const frameAddonParentId = +selectedFrameField?.dataset.parentId;

    const addonSelect = formFieldsArray.find(field => field.getAttribute('name') === 'addonId') as HTMLSelectElement;

    const addons: string[] = [];

    let productId: number = +selectedProductField?.getAttribute('data-value');
    let productMargin = withMarginRadio?.checked ? Margin.WithMargin : Margin.WithoutMargin;

    const isExistingCartItem = submitButton.hasAttribute('data-cartitem');

    btn.classList.add('is-processing');

    // BUGFIX: make sure the added frame is a child to the selected product
    // This should fix the problem with certain orders where a 21x30 frame has been added to a larger product
    // if not existing cart item and frame is selected and frame parent id does not match selected product id
    if (!isExistingCartItem && !isNaN(frameAddonValue) && productId != frameAddonParentId) {
      // console.warn('The selected frame does not relate to product and will not be added to cart.');
      frameAddonValue = null;
    }

    // if no frame is selected check if other addon/accessory is selected
    if (isNaN(frameAddonValue)) {
      frameAddonValue = +addonSelect?.value;
    }

    // customer has chosen a frame AND an additional addon/accessory
    if (+selectedFrameField?.dataset.value > 0 && +addonSelect?.value > 0) {
      addons.push(addonSelect.value);
    }

    // passepartout
    if (passepartoutRadio && passepartoutRadio?.checked) {
      // set selected product to have margin
      productMargin = Margin.WithMargin;

      addons.push(passepartoutRadio.value);
    }

    // if editing product
    if (!productId) {
      productId = +editProductField?.value;
    }

    const cartItem: CartItemAdd = {
      photoId: +submitButton.dataset.photo,
      productId: productId,
      addon: frameAddonValue,
      addons: addons,
      options: {
        Margin: productMargin,
      },
    };

    // if we are editing an existing cart item
    if (isExistingCartItem) {
      const existingCartItemId = +submitButton.dataset.cartitem;

      // delete existing cartitem before setting up a new updated cart item
      CartService.deleteProduct(existingCartItemId).then(() => {
        // add updated item
        CartService.addProduct(cartItem)
          .then(() => {
            Utilities.log('Updated cart item added to cart');

            // render cart content
            Cart.renderCart();

            // push datalayer add to cart event for the added addon
            Ecommerce.pushUpsellsAddonAddToCartEvent(frameAddonValue, selectedFrameField?.dataset.type, selectedFrameField?.dataset.price);
          })
          .catch(error => {
            Utilities.log(error);
            const validationErrors = document.querySelector('.js-product-selector-validation-errors');
            validationErrors.innerHTML = 'An error occurred when updating the cart item.';
            validationErrors.classList.remove('d-none');

            // render cart content
            Cart.renderCart();

            return;
          });
      });

      // close modal and remove backdrop
      Cart.closeModal(document.querySelector(Cart.EditCartItemModalSelector));
    }
    // add new item to cart
    else {
      CartService.addProduct(cartItem).then(result => {
        if (result) {
          // Utilities.log('Product added to cart');
        } else {
          Utilities.log('Error when adding product to cart');
        }

        // add facebook event tracking.
        // get a unique id and use same event id and pass to conversion api
        const eventId = FacebookConversionService.getUniqueEventId();

        // fetch price
        const decimalPrice = selectedProductField?.getAttribute('data-price-value');
       
        /* eslint-disable @typescript-eslint/camelcase */
        const customData = {
          content_type: 'product',
          content_ids: [cartItem.photoId],
          currency: window.Printler.Currency,
          value: decimalPrice,
        };
        /* eslint-enable @typescript-eslint/camelcase */

        window.Printler.FacebookPixel.Track('AddToCart', customData, { eventID: eventId });

        const fbEvent: FacebookEvent = {
          id: `${eventId}`,
          name: 'AddToCart',
          sourceUrl: window.location.href,
          customData: customData,
        };

        // facebook conversion api
       FacebookConversionService.pushEventAsync(fbEvent);

        // render cart content
        Cart.renderCart();

        // wait for cart to render then push datalayer add to cart event
        setTimeout(() => {
          Ecommerce.pushAddToCartEvent(cartItem);
        }, 1000);

        // add to cart animation
        Cart.addToCartBtnAnimation(submitButton);
      });
    }
  }

  /**
   * Add addon/accessory to cart
   * @param btn - The add to cart button element
   */
  static addAccessoryToCart(btn: HTMLElement): void {
    const cartItem: CartItemAddonAdd = {
      parentId: null,
      addon: +btn.dataset.addonId,
    };

    // add spinning icon
    btn.classList.add('is-processing');

    // add product with addon flag
    CartService.addProductAddon(cartItem).then(() => {
      Utilities.log('Addon added to cart');

      // render cart content
      Cart.renderCart();

      // wait for cart to render then push datalayer add to cart event
      setTimeout(() => {
        Ecommerce.pushAddonAddToCartEvent(cartItem);
      }, 1000);

      // add to cart animation
      Cart.addToCartBtnAnimation(btn);
    });
  }

  /**
   * Delete item from cart
   * @param e - delete click event
   */
  static deleteFromCart(e: Event): void {
    e.preventDefault();

    const deleteButton = e.currentTarget as HTMLElement;

    CartService.deleteProduct(+deleteButton.dataset.item).then(response => {
      if (!response) {
        Utilities.log('Error when removing product from cart');
        return;
      }

      Utilities.log('Product removed from cart');

      // fetch item data before refreshing cart
      Ecommerce.pushRemoveFromCartEvent(deleteButton.dataset.item);

      // render updated cart content
      Cart.renderCart();
    });
  }

  /**
   * Render cart content
   */
  static renderCart(): void {
    const spinner = document.createElement('div');
    spinner.classList.add('mx-auto', 'd-flex', 'a-spinner');

    // show loader
    Cart.MiniCartContainer.innerHTML = '';
    Cart.MiniCartContainer.appendChild(spinner);

    CartService.getCartHtml().then(result => {
      setTimeout(() => {
        spinner.remove();
        Cart.MiniCartContainer.innerHTML = result;

        // re-init listeners for cart content
        Cart.setupDeleteItemEventListener();
        Cart.setupCloseMiniCartEventListener();
        Cart.setupEditItemEventListener();
      }, 100);
    });

    // get number of first level items of current language and set count
    CartService.getCartItems().then(result => {
      let cartCount = 0;

      result?.forEach(item => {
        if (item.ParentKey === null && `${item.Root}` === window.Printler.StoreId) {
          cartCount++;
        }
      });

      const cartCountBadge: HTMLElement = document.querySelector(this.CartCountSelector);
      cartCountBadge.innerText = cartCount.toString();

      if (+cartCountBadge.innerText === 0) {
        cartCountBadge.classList.add('d-none');
      } else {
        cartCountBadge.classList.remove('d-none');
      }
    });
  }

  /**
   * Add a simple effect via css
   * @param button - the button element where to append class
   */
  static async addToCartBtnAnimation(button: HTMLElement): Promise<void> {
    button.classList.remove('is-processing');
    button.classList.add('to-cart');

    setTimeout(() => {
      button.classList.remove('to-cart');
    }, 2000);
  }
}

export default Cart;
